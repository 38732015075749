// Import mixins
@import 'components/mixins.scss';

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #2b7deb;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
  content: unset !important;
}

.ant-form-item {
  padding-left: rem(9) !important;
  padding-right: rem(9) !important;
}

.ant-input {
  border-radius: 6px !important;
  &:hover,
  &:focus,
  &:active {
    border-color: $primary;
  }
}

.ant-input-affix-wrapper {
  border-radius: 6px !important;
  &:hover,
  &:focus,
  &:active {
    .ant-input:not(.ant-input-disabled) {
      border-color: $primary;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-input {
    &-number {
      &-handler {
        &-wrap {
          background: $dark-gray-3;
        }
      }
    }
  }
}
