@import 'css/layout';
@import 'css/tabs';
@import 'css/notification';
@import 'css/checkbox';
@import 'css/tag';
@import 'css/dropdown';
@import 'css/radio';
@import 'css/button';
@import 'css/empty';
@import 'css/select';
@import 'css/modal';
@import 'css/calendar';
@import 'css/progress';
@import 'css/upload';
@import 'css/slider';
@import 'css/collapse';
@import 'css/card';
@import 'css/cascader';
@import 'css/input';
@import 'css/mentions';
@import 'css/time';
@import 'css/skeleton';
@import 'css/breadcrumb';
@import 'css/menu';
@import 'css/table';

@media print {
  .hidden-print {
    display: none !important;
  }
}
