// Import mixins
@import 'components/mixins.scss';

.ant-modal-confirm-btns {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
}
.ant-modal-body {
  padding: 0 !important;
  // padding: rem(15) 0 0 0 !important;
}
.ant-modal-confirm-title {
  color: #1a233a;
}
.ant-modal {
  &-mask {
    background: rgba($gray-1, 0.9);
  }
  &-header {
    border-color: transparent;
  }
  &-footer {
    border-color: transparent;
  }
  &-content {
    box-shadow: $shadow-3;
    border-radius: rem(15) !important;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-modal {
    &-mask {
      background: rgba($black, 0.84);
    }
    &-close-x {
      color: $gray-5;
      &:hover {
        color: $gray-3;
      }
    }
    &-content {
      background: #131020;
    }
    &-header {
      background: #131020;
      border-color: #131020;
    }
    &-footer {
      border-color: #131020;
    }
  }
}
